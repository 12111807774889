<template>
  <el-container>
    <el-header><pageHeader></pageHeader></el-header>
    <el-container>
      <el-aside width="100px"><pageAside cindex="0"></pageAside></el-aside>
      <el-main>
        <div class="container">
          <el-page-header @back="goBack" :content="pageheaderTitle"> </el-page-header>
          <div class="contBox">
            <div class="leftPart">
              <div class="partHead">
                <div class="partHeadTitle">本测评活动还未分配小组的学员列表<div class="nums">未分组人数：{{noGroupStudentCount}}  |  已分组人数：{{hasGrouplStudentCount}}</div></div>
              </div>
              <div class="searchPart">
                <el-select
                  v-model="value"
                  filterable
                  size="mini"
                  remote
                  clearable
                  reserve-keyword
                  placeholder="搜索考生姓名"
                  :remote-method="remoteMethod"
                  :loading="loading"
                  @change="searchChange"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.stuName"
                    :label="item.stuName"
                    :value="item.stuName"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="theGroupBox">
                <groupBox
                  :list="list1"
                  group="group"
                  gtype="left"
                  :searchVal='searchVal'
                  @changeChildItem="changeChildItem"
                />
              </div>
            </div>
            <div class="rightPart">
              <div class="partHead">
                <div class="partHeadTitle" v-if="!groupNo">小组考生列表</div>
                 <div class="partHeadTitle" v-else>测评第{{groupNo}}组考生列表</div>
                <div class="saveBtns">
                  <el-button
                    type="success"
                    :loading="saveLoading"
                    size="mini"
                    :disabled="list2.length <=oldLength"
                    @click.native.prevent="saveGroup"
                    >保存</el-button
                  >
                  <el-button
                    type="primary"
                    v-if="!groupNo"
                    size="mini"
                    :disabled="list2.length == 0"
                    @click.native.prevent="clearGroup"
                    >清空</el-button
                  >
                </div>
              </div>
              <div class="theGroupBox">
                <groupBox
                  :list="list2"
                  group="group"
                  gtype="right"
                  @changeChildItem="changeChildItem"
                />
              </div>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import {
  mapState
} from 'vuex';
import PinyinMatch from 'pinyin-match';
import pageHeader from '@/components/pageHeader.vue';
import pageAside from '@/components/pageAside.vue';
import groupBox from '@/components/group/groupBox.vue'
export default {
  components: {
    pageHeader,
    pageAside,
    groupBox
  },
  computed: mapState(['testInfo', 'userInfo']),
  data() {
    return {
      options: [],
      value: [],
      rawList: [],
      list1: [],
      list2: [],
      groupNo: '',
      pageheaderTitle:'新建分组',
      oldLength:0,
      loading: false,
      saveLoading: false,
      isChangeGroup: false,
      noGroupCount: '',
      searchVal:'',
      noGroupStudentCount:'',
      hasGrouplStudentCount:''
    }
  },
  created() {
    this.getFreeExamineesList();
    let groupNo = this.$route.query.groupNo;
    this.groupNo = groupNo;
    if (groupNo) {
      this.pageheaderTitle = '修改第'+groupNo+'组';
      this.getGroupWithDetailList(groupNo);
      this.isChangeGroup = true;
    }
  },
  methods: {
    getFreeExamineesList(filterName) {
      const that = this;
      let param = {
        token: that.userInfo.token,
        sitesId: that.testInfo.sitesId,
        filterName: filterName
      }
      that.$api.getFreeExamineesList(param).then(res => {
        if (res.success) {
          that.rawList = JSON.parse(JSON.stringify(res.body.list));
          that.list1 = res.body.list;
          that.noGroupStudentCount = res.body.noGroupStudentCount;
          that.hasGrouplStudentCount = res.body.totalStudentCount-res.body.noGroupStudentCount;
        } else {
          that.$message(res.msg)
        }
      })
    },
    getGroupWithDetailList(groupNo) {
      const that = this;
      let param = {
        token: that.userInfo.token,
        sitesId: that.testInfo.sitesId,
        groupNo: groupNo
      }
      that.$api.getGroupWithDetailList(param).then(res => {
        if (res.success) {
          let result = res.body.list[0].examineesList;
          that.oldLength = result.length;
          this.noGroupCount = res.body.noGroupCount;
          result.forEach(function (e) {
            e.cannotChange = true;
          })
          that.list2 = result;
        } else {
          that.$message(res.msg)
        }
      })
    },
    saveGroup() {
      const that = this;
      that.saveLoading = true;
      let examineesArray = [];

      let param = {
        token: that.userInfo.token,
        sitesId: that.testInfo.sitesId,
      }
      if (that.groupNo) {
        //已存在组添加新成员
        param.groupNo = that.groupNo;
        let paramArray = that.list2;
        paramArray = paramArray.filter(item => item.cannotChange != true);
        console.log(paramArray);
        paramArray.forEach(function (item) {
          examineesArray.push(item.examNo)
        })
      }
      else {
        //新建分组
        that.list2.forEach(function (item) {
          examineesArray.push(item.examNo)
        })
      }
      param.examineesArray = examineesArray;
      that.$api.addGroup(param).then(res => {
        if (res.success) {
          if (that.groupNo) {
            that.getFreeExamineesList();
            that.getGroupWithDetailList(that.groupNo);
            that.$alert('保存分组成功', '提示', {
              confirmButtonText: '确定',
              callback: action => {
                that.goBack();
              }
            })
          }
          else {
            that.list2 = [];
            that.getFreeExamineesList();
            that.$confirm('保存分组成功', '提示', {
              confirmButtonText: '开始下个分组',
              cancelButtonText: '返回',
              type: 'success'
            }).then(() => {

            }).catch(() => {
              that.goBack()
            })
          }

        } else {
          that.$message(res.msg)
        }
        that.saveLoading = false;
      })
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.options = this.list1.filter(item => {
            return PinyinMatch.match(item.stuName, query)
            // return item.stuName.toLowerCase()
            //   .indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
    changeChildItem(data) {
      if (data.gtype == 'left') {
        let moveData = this.list1[data.index];
        this.list1.splice(data.index, 1);
        this.list2.push(moveData);
      }
      else {
        let moveData = this.list2[data.index];
        this.list2.splice(data.index, 1);
        this.list1.push(moveData);
      }
    },
    searchChange(e) {
      this.searchVal=e
    },
    clearGroup() {
      const that = this;
      that.$confirm('确认清空当前分组？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        that.list1 = JSON.parse(JSON.stringify(that.rawList));
        that.list2 = [];
      }).catch(()=>{

      })
    },
    goBack() {
      this.$router.back();
    }
  }
};
</script>
<style >
#app {
  display: flex;
}
.el-header {
  background-color: #2797ff;
  height: 85px !important;
}

.el-aside {
  background-color: #2797ff;
}

.el-main {
  background-color: #0e8bff;
  color: #ffffff;
}

body > .el-container {
  margin-bottom: 40px;
  overflow: hidden;
}
</style>
<style lang="scss" scoped>
/deep/ .el-page-header__content {
  color: #ffffff;
}
.contBox {
  margin: 30px 20% 30px 7%;
  background: #ffffff;
  box-shadow: 1px 1px 12px 2px rgba($color: #f6f7f8, $alpha: 0.3);
  height: 700px;
  display: flex;
  .leftPart {
    border-right: 1px solid #d2d2d2;
    flex: 3;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    .searchPart {
      margin-top: 20px;
    }
  }
  .rightPart {
    flex: 2;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  .partHead {
    display: flex;
    .partHeadTitle {
      line-height: 30px;
      font-size: 18px;
      font-weight: 700;
      color: #333;
      flex: 1;
      display: flex;
      .nums{
        flex: 1;
        font-size: 14px;
        font-weight: normal;
        text-align: right;
      }
    }
  }
  .theGroupBox {
    margin-top: 30px;
    flex: 1;
    overflow-y: scroll;
  }
}
</style>