<template>
  <div class="board-column">
    <div class="tipBox">
      <el-alert
        v-if="gtype == 'right'"
        title="拖拽头像可排序"
        type="info"
        show-icon
      >
      </el-alert>
      <el-alert
        v-else
        title="点击或拖拽头像到右边来添加组员"
        show-icon
        type="info"
      >
      </el-alert>
    </div>
    <draggable
      :list="list"
      v-bind="$attrs"
      class="board-column-content"
      :set-data="setData"
      filter=".forbid"
    >
      <div v-for="(element,index) in list" :key="index" v-if="searchVal==''||(searchVal!=''&&searchVal==element.stuName)" :class="element.cannotChange==true?'board-item forbid':'board-item'"  @click="changeChildItem(index)">
        <el-image
          v-if="element.regPhoto"
          :src="element.regPhoto"
          class="regPhoto"
          fit="cover"
        ></el-image>
        <el-image
          v-else-if="element.stuSex=='male'"
          :src="require('@/assets/images/male.png')"
          class="regPhoto"
          fit="cover"
        ></el-image>
        <el-image
          v-else-if="element.stuSex=='female'"
          :src="require('@/assets/images/female.png')"
          class="regPhoto"
          fit="cover"
        ></el-image>
        <el-image
          v-else
          :src="require('@/assets/images/defaultUserPic.png')"
          class="regPhoto"
          fit="cover"
        ></el-image>
        <span class="delTip" v-if="gtype=='right'&&!element.cannotChange">删除</span>
        <span class="stuName"> {{ element.stuName }}</span>
      </div>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'DragKanbanDemo',
  components: {
    draggable
  },
  props: {
    gtype: {
      type: String,
      default() {
        return ''
      }
    },
    options: {
      type: Object,
      default() {
        return {}
      }
    },
    searchVal:{
      type: String,
      default() {
        return ''
      }
    },
    list: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
      changeChildItem(index){
        if(this.gtype=='right'&&this.list[index].cannotChange){
          this.$message('已分组成员不可改动，如需转移分组请返回上一页');
          return;
        }
          let param = {
              index:index,
              gtype:this.gtype
          }
          this.$emit('changeChildItem',param);
      },
    setData(dataTransfer) {
      // to avoid Firefox bug
      // Detail see : https://github.com/RubaXa/Sortable/issues/1012
      dataTransfer.setData('Text', '')
    }
  }
}
</script>
<style lang="scss" scoped>
.board-column {
  height: auto;
  min-height: 500px;
  overflow: hidden;
  border-radius: 3px;

  .board-column-content {
    height: auto;
    overflow: hidden;
    border: 10px solid transparent;
    min-height: 500px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    .board-item {
      cursor: pointer;
      width: 90px;
      height: 110px;
      margin: 10px;
      background-color: #f2f2f2;
      color: #333;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: all linear 0.3s;
      position: relative;
      &:hover {
        box-shadow: 0px 0px 12px 2px rgba($color: #6b6b6b, $alpha: 0.3);
      }
      &.forbid{
        .stuName{
          color: #999;
        }
      }
      .delTip{
          position: absolute;
          font-size: 12px;
          color: #ffffff;
          background-color: #fa504c;
          line-height: 20px;
          padding: 0 4px;
          z-index: 2;
          right: 0;
          top: 0;
      }
      .regPhoto {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
      .stuName {
        font-size: 14px;
        margin-top: 10px;
      }
    }
  }
  .tipBox {
    padding: 10px;
  }
}
</style>

