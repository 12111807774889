<template>
  <div class="asideList">
    <div class="asideItem" :class="{'activeItem':cindex==0}" @click="gotoNav(0)">
      <div class="imgBox">
        <el-image
          :src="require('@/assets/images/fenzu.png')"
          class="asideImg"
        ></el-image>
      </div>
      <span class="asideName">现场分组</span>
    </div>
     <div class="asideItem" :class="{'activeItem':cindex==1}" @click="gotoNav(1)">
      <div class="imgBox">
        <el-image
          :src="require('@/assets/images/paishe.png')"
          class="asideImg"
        ></el-image>
      </div>
      <span class="asideName">表演视频上传</span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'pageAside',
  props: {
    cindex: String
  },
  methods:{
    gotoNav(index){
      if(index==this.cindex){
        return;
      }
      else{
        if(index==0){
          this.$router.push({
            path:'/'
          })
        }
        else{
           this.$router.push({
            path:'/selectRoom'
          })
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.asideList {
  display: flex;
  flex-direction: column;
  width: 100px;
  border-top: 1px solid #0e8bff;
  .asideItem {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    &.activeItem{
      background-color: #2185e7;
    }
    .imgBox {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffffff;
      border-radius: 4px;
      .asideImg {
        width: 25px;
      }
    }
    .asideName{
      line-height: 20px;
      font-size: 14px;
      color: #ffffff;
      margin-top: 10px;
    }
  }
}
</style>
